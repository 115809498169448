/**
 * 将多条线段合并成一条线段的函数
 * @param {FeatureCollection} featureCollection - 输入的FeatureCollection，包含多条线段
 * @returns {FeatureCollection} - 返回一个新的FeatureCollection，其中包含合并后的线段
 */
export function mergeMultiLineStrings(featureCollection) {
  // 解析输入的FeatureCollection中的所有线段
  let features = []
  for (let item of featureCollection.features) {
    // 如果当前Feature是一个MultiLineString，则合并
    if (item.geometry.type == 'MultiLineString') {
      features.push(mergeFeatures(item))
    } else {
      features.push(item)
    }
  }
  return {
    type: 'FeatureCollection',
    features: features
  }
}

/**
 * 合并线段的函数
 * @param {Feature} feature - 输入的Feature，包含线段坐标和属性
 * @returns {Feature} - 返回一个新的Feature，其中包含合并后的线段坐标和原始属性
 */
function mergeFeatures(feature) {
  let lines = feature.geometry.coordinates
  let properties = feature.properties
  // 用于存储合并后的线段
  let mergedLines = []

  // 标记哪些线段已经被处理过
  const processed = new Array(lines.length).fill(false)

  // 主循环来查找并合并线段
  for (let i = 0; i < lines.length; i++) {
    if (!processed[i]) {
      let currentLine = lines[i]
      processed[i] = true
      for (let j = i + 1; j < lines.length; j++) {
        if (!processed[j]) {
          const merged = tryMerge(currentLine, lines[j])
          if (merged !== null) {
            currentLine = merged
            processed[j] = true
          }
        }
      }
      mergedLines.push(currentLine)
    }
  }
  // 构建新的Feature
  return {
    type: 'Feature',
    geometry: {
      type: 'MultiLineString',
      coordinates: mergedLines
    },
    properties: properties
  }
}

/**
 * 检查两个坐标是否相等
 * 该函数用于比较两个坐标数组是否完全相等
 * @param {Array} a - 第一个坐标数组
 * @param {Array} b - 第二个坐标数组
 */
function areCoordinatesEqual(a, b) {
  // 使用Array.prototype.every方法遍历坐标数组并比较每个维度的值
  return a.every((val, index) => val === b[index])
}

/**
 * 尝试将两个线段连接起来
 * 该函数尝试将两个线段连接成一个线段，如果可以连接，则返回新的线段数组；否则返回null
 * @param {Array} line1 - 第一个线段数组
 * @param {Array} line2 - 第二个线段数组
 * @returns {Array|null} - 连接后的线段数组，如果无法连接则返回null
 */
function tryMerge(line1, line2) {
  // 检查第一个线段的末尾坐标与第二个线段的起始坐标是否相等
  if (areCoordinatesEqual(line1[line1.length - 1], line2[0])) {
    // 如果相等，则将两个线段连接起来，并返回新的线段数组
    return [...line1, ...line2.slice(1)]
  } else if (areCoordinatesEqual(line1[0], line2[line2.length - 1])) {
    // 检查第一个线段的起始坐标与第二个线段的末尾坐标是否相等
    // 如果相等，则将两个线段以相反的顺序连接起来，并返回新的线段数组
    return [...line2.slice(0, -1), ...line1]
  }
  // 如果两个线段无法连接，则返回null
  return null
}
