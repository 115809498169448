<!--
 * @Name: 组件名
 * @Author: koyoyoo
 * @Date: 2025-01-16 16:35:21
-->
<template>
  <div class="cpn-build">
    <KOimg :src="img"></KOimg>
    <div class="name">页面正在建设中~</div>
  </div>
</template>
<script>
export default {
  inject: [],
  components: {},
  props: {},
  data() {
    return {
      img: require('@/assets/img/build.jpg')
    }
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {}
}
</script>
<style lang="scss" scoped>
.cpn-build {
  .ko-img {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }
  .name {
    font-size: 18px;
    margin-bottom: 30px;
    text-align: center;
  }
}
</style>
