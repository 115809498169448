<!--
 * @Name: 组件名
 * @Author: koyoyoo
 * @Date: 2024-09-26 16:41:53
-->
<template>
  <div class="cpn-map-wrap">
    <boxOxyMapLegend class="top-bar"></boxOxyMapLegend>
    <MapRightDataView
      v-if="brandType == 1"
      class="m-layer-right"
      @handlerChange="EventChange_handler"
    ></MapRightDataView>
    <OxyRightDataView
      v-else
      class="m-layer-right"
      @handlerChange="EventChange_handler"
    ></OxyRightDataView>
    <div
      class="cpn-map-inner"
      :class="handler"
      v-loading="MapData.loading"
      element-loading-text="数据加载中..."
      element-loading-background="rgba(255, 255, 255, 0.9)"
    >
      <div :id="mapId" class="map-container"></div>
      <MapEXNum log="审图号"></MapEXNum>
      <Map9Line log="9段线"></Map9Line>
      <MapZoomButton></MapZoomButton>
      <MapPopup></MapPopup>
      <MarkerYearLegend></MarkerYearLegend>
      <MapSelectArea></MapSelectArea>
      <CNMarkerHoverInteraction v-if="map" :map="map">
        <template #default="{ markerData }">
          <slot name="marker-interaction" :markerData="markerData"></slot>
        </template>
      </CNMarkerHoverInteraction>
    </div>
  </div>
</template>
<script>
import boxOxyMapLegend from '@/components/view/map/modelMapOxyView/legend/boxOxyMapLegend.vue'
import MapEXNum from '@/components/common/map/layer/MapEXNum.vue'
import Map9Line from '@/components/common/map/layer/Map9Line.vue'
import MapSelectArea from './layer/MapSelectArea'
import OxyRightDataView from './layer/OxyRightDataView'
import MapRightDataView from './layer/MapRightDataView'
import MapZoomButton from './layer/MapZoomButton'
import CNMarkerHoverInteraction from '@/components/content/map/cpn/CNMarkerHoverInteraction.vue'
import MapPopup from './layer/mapPopup/MapPopup'
import MarkerYearLegend from './layer/MarkerYearLegend'
import { drawMarkers } from './js/drawMarkers.js'
import { isPointInGeojson } from '@/plug/map/openLayer/polygon/utilsPolygon.js'
import {
  MapData,
  initMapData,
  initPlugDistributionAdminDivi
} from './js/MapPlugAdminPolygon.js'
import {
  initMap,
  resizeMap,
  destroyMap
} from '@/components/view/map/modelMapOxyView/js/initMap.js'
import { InfoMap_adminDiviOuter } from '@/info/infoMap.js'
export default {
  components: {
    boxOxyMapLegend,
    MapEXNum,
    Map9Line,
    MapSelectArea,
    OxyRightDataView,
    MapRightDataView,
    MapZoomButton,
    MapPopup,
    MarkerYearLegend,
    CNMarkerHoverInteraction
  },
  props: {
    mapId: {
      type: String,
      default: 'map-oxy-view'
    },
    brandType: {
      type: Number,
      default: 3
    },
    markerList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      MapData,
      handler: 'close',
      map: null
    }
  },
  watch: {
    markerList: {
      handler(newVal) {
        drawMarkers(newVal)
      }
    },
    'MapData.areaCode': {
      handler() {
        this.drawMarkersByGeojson()
      }
    }
  },
  computed: {},
  created() {
    initMapData(this.brandType)
  },
  mounted() {
    const map = initMap({
      id: this.mapId
    })
    this.map = map
    initPlugDistributionAdminDivi()
    this.drawMarkersByGeojson()
  },

  beforeUnmount() {
    destroyMap()
  },
  methods: {
    drawMarkersByGeojson() {
      InfoMap_adminDiviOuter({ areaCode: this.MapData.areaCode }).then(
        (geojsonOuter) => {
          let list = []
          for (let item of this.markerList) {
            if (isPointInGeojson([item.lon, item.lat], geojsonOuter)) {
              list.push(item)
            }
          }
          drawMarkers(list)
        }
      )
    },
    EventChange_handler(ret) {
      this.handler = ret
      this.$nextTick(() => {
        resizeMap()
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.cpn-map-wrap {
  height: 100%;
}
.cpn-map-inner {
  position: relative;
  &.open {
    margin-right: 380px;
  }
  .map-container {
    height: 680px;
    ::v-deep(.ol-viewport) {
      position: static !important;
    }
  }
}
.m-layer-right {
  position: absolute;
  top: 50px;
  right: 0;
  z-index: 20;
}
</style>
