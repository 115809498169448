import OMU from '@/plug/map/openLayer/utils/utils.js'
import GeoJSON from 'ol/format/GeoJSON'
import { fromLonLat } from 'ol/proj' // 引入投影转换工具
import { getVectorContext } from 'ol/render'
import { getBoundsByGeojson } from '@/plug/map/openLayer/polygon/utilsPolygon.js'
import { Fill, Style } from 'ol/style'

export function getBounds(geoJsonOuter) {
  let polygons = geoJsonOuter.features[0].geometry.coordinates[0]
  let bounds = OMU.getBoundsByPolygon(polygons)
  return bounds
}

export function setLayerFeatures(layer, features) {
  if (layer) {
    let source = layer.getSource()
    source.clear()
    source.addFeatures(features)
  }
}

/**
 * 删除FeatureCollection中包含少于3个点的MultiPolygon或Polygon类型要素
 * @param {Object} featureCollection - 输入的FeatureCollection对象
 * @returns {Object} - 处理后的FeatureCollection对象
 */
export function removeInvalidFeatures(featureCollection) {
  const features = featureCollection.features
  const validFeatures = []

  features.forEach((feature) => {
    const geometryType = feature.geometry.type
    const coordinates = feature.geometry.coordinates

    if (geometryType === 'Polygon') {
      if (coordinates[0].length >= 3) {
        validFeatures.push(feature)
      }
    } else if (geometryType === 'MultiPolygon') {
      const validPolygons = coordinates.filter(
        (polygon) => polygon[0].length >= 3
      )
      if (validPolygons.length > 0) {
        feature.geometry.coordinates = validPolygons
        validFeatures.push(feature)
      }
    } else {
      validFeatures.push(feature) // 如果不是Polygon或MultiPolygon类型，则保留原始要素
    }
  })

  featureCollection.features = validFeatures
  return featureCollection
}

/**
 * 将图例数组转换为区间对象
 * @param {Array} target - 图例数组，每个元素包含val, name, color, colors属性
 * @returns {Object} - 转换后的区间对象，键为区间，值为对应的颜色
 */
export function convertToRangeObject(target) {
  if (!target || !Array.isArray(target)) {
    console.error('图例数组未定义')
    return {}
  }
  // 创建区间键
  const createRangeKey = (start, end) => `${start}-${end}`

  // 使用reduce方法遍历数组并构造区间对象
  const result = target.reduce((acc, item, index, array) => {
    if (index < array.length - 1) {
      const nextItem = array[index + 1]
      const rangeKey = createRangeKey(item.val, nextItem.val)
      acc[rangeKey] = item.color
    }
    return acc
  }, {})

  // 单独处理最后一个元素
  result[
    createRangeKey(target[target.length - 1].val, target[target.length - 1].val)
  ] = target[target.length - 1].color

  return result
}

/**
 * 根据给定的值获取对应的颜色
 * @param {Object} colorMap - 包含范围和颜色的对象
 * @param {number} value - 要查找的值
 * @returns {string|null} - 返回对应的颜色，如果没有找到匹配的范围则返回 null
 */
export function getColorForValue(colorMap, value) {
  // 遍历对象的每个键
  for (const range in colorMap) {
    // 分割范围
    const [min, max] = range.split('-').map(Number)
    // 检查值是否在范围内
    if (value >= min && value <= max) {
      return colorMap[range] // 返回对应的颜色
    }
  }
  return 'rgba(0,0,0,0)' // 如果没有找到匹配的范围，返回 null
}

export function extendLayer(Layer, geojson) {
  if (geojson.features?.length) {
    let clipFeature = new GeoJSON().readFeature(geojson.features[0], {
      dataProjection: 'EPSG:4326', // 输入数据的投影坐标系
      featureProjection: 'EPSG:4326' // 输出 feature 的投影坐标系
    })
    Layer.setExtent(clipFeature.getGeometry().getExtent())
    Layer.on('prerender', (event) => {
      let vectorContext = getVectorContext(event)
      event.context.globalCompositeOperation = 'source-over'
      let ctx = event.context
      ctx.save()
      /*
			event.context.filter = 'brightness(80%) saturate(120%) hue-rotate(60deg)' // 增加亮度和饱和度
			*/
      vectorContext.drawFeature(
        clipFeature,
        new Style({
          fill: new Fill({
            color: 'rgba(13, 46, 77, 0)'
          })
        })
      ) // 可以对边界设置一个样式
      ctx.clip()
    })
    Layer.on('postrender', (event) => {
      let ctx = event.context
      ctx.restore()
      //colorizeCanvas(ctx.canvas)
    })
  }
}

export function viewGeojson(map, geojson, padding) {
  let bounds = getBoundsByGeojson(geojson)
  viewBounds(map, bounds, padding)
}
/**
 * 按照bounds显示当前地图视图区域
 * @param {Array} bounds  //[103,29,105,30]
 * @param {Array} padding  //[0,0,0,0]
 */

export function viewBounds(map, bounds, padding) {
  if (bounds) {
    let reArr = []
    const currentProjection = map.getView().getProjection().getCode() // 获取当前地图的投影
    if (currentProjection === 'EPSG:3857') {
      // 当前投影是 EPSG:3857，直接将 EPSG:4326 的边界转换为 EPSG:3857
      const bottomLeft = fromLonLat([bounds[0], bounds[1]]) // 左下角
      const topRight = fromLonLat([bounds[2], bounds[3]]) // 右上角
      reArr = [...bottomLeft, ...topRight]
    } else if (currentProjection === 'EPSG:4326') {
      // 当前投影是 EPSG:4326，直接使用边界
      reArr = bounds
    }
    // 适应视图到边界
    map.getView().fit(reArr, { padding })
  }
}

export function reData_coords(coord) {
  let reData = []
  if (coord.length == 2) {
    return fromLonLat(coord)
  } else {
    for (let item of coord) {
      reData.push(fromLonLat(item))
    }
    return reData
  }
}

/**
 * 过滤一个GeoJSON对象，仅包含在指定边界内的点。
 * @param {Object} geojson - 一个包含点特征的GeoJSON对象，类型为FeatureCollection。
 * @param {Array<number>} bounds - 一个包含四个数字的数组，表示边界框 [minLat, minLng, maxLat, maxLng]。
 * @returns {Object} 一个新的GeoJSON对象，仅包含在指定边界内的点。
 * @throws {Error} 如果输入的GeoJSON不是有效的FeatureCollection或不包含特征，则抛出错误。
 */
export function filterGeoJSONByBounds(geojson, bounds) {
  const [minLat, minLng, maxLat, maxLng] = bounds
  // 检查是否是点类型的GeoJSON
  if (geojson.type !== 'FeatureCollection' || !geojson.features) {
    throw new Error(
      'Invalid GeoJSON: Must be a FeatureCollection with features.'
    )
  }
  // 过滤在边界内的点
  const filteredFeatures = geojson.features.filter((feature) => {
    if (feature.geometry.type !== 'Point') {
      return false
    }

    const [lng, lat] = feature.geometry.coordinates
    return lat >= minLat && lat <= maxLat && lng >= minLng && lng <= maxLng
  })
  // 构造新的GeoJSON对象
  const filteredGeoJSON = {
    type: 'FeatureCollection',
    features: filteredFeatures
  }

  return filteredGeoJSON
}

/**
 * 通过图例列表获取当前值的图例颜色
 * @param {Array} legendList
 * @param {String,Number} value
 * @returns {String} color
 */
export function getColorByLegend(list, value) {
  const legendList = [...list].sort((a, b) => {
    return a.val - b.val
  })
  if (legendList?.length && typeof value !== 'undefined') {
    for (let i = 0; i < legendList.length; i++) {
      if (typeof legendList[i + 1] !== 'undefined') {
        if (value >= legendList[i].val && value < legendList[i + 1].val) {
          return legendList[i].color
        }
      }
    }
    if (value < legendList[0].val) {
      return legendList[0].color
    } else if (value > legendList[legendList.length - 1].val) {
      return legendList[legendList.length - 1].color
    }
  }
  return '#FFF'
}

/**
 * 从字符串中提取两个断点数值
 * 该函数主要用于解析包含两个断点的字符串，这两个断点之间以"-"相连
 * 并且可能会包含空格和其他字符该函数将忽略这些字符
 *
 * @param {string} str - 待解析的字符串，预期包含两个以"-"相连的断点数值
 * @returns {Array} - 包含两个断点数值的数组，第一个和第二个断点分别作为数组的第一个和第二个元素
 */
export function getBreakNumbers(str) {
  // 正则表达式用于匹配字符串中的两个断点数值
  // 忽略了断点之间的空格和其他字符
  const regex = /^(-?\d+(\.\d+)?)\s*-\s*(-?\d+(\.\d+)?)/
  // 使用正则表达式匹配字符串
  const match = str.match(regex)
  // 第一个数值，通过正则表达式匹配获得
  const num1 = match[1]
  // 第二个数值，通过正则表达式匹配获得
  const num2 = match[3]
  // 返回包含两个断点数值的数组
  return [num1, num2]
}

/**
 * 站点数据抽稀
 * @param {Object} map
 * @param {Array} stations
 * @returns  {Array}
 */
export function reDataStationDilution(stations) {
  let viewMarker = []
  // let dict = {};
  for (let item of stations) {
    // if (typeof dict[item.stationLevl] == "undefined") {
    // 	dict[item.stationLevl] = [];
    // }
    // dict[item.stationLevl].push(item);
    if (item.stationLevl) {
      if ([0, 1, 2, 3, 15].includes(item.stationLevl)) {
        item.minZoom = 4
      } else if ([24, 25, 28, 31, 34, 35, 40].includes(item.stationLevl)) {
        item.minZoom = 5
      } else if ([45, 50, 57, 64, 74, 82].includes(item.stationLevl)) {
        item.minZoom = 6
      } else if (
        [93, 104, 118, 133, 151, 170, 192, 1500].includes(item.stationLevl)
      ) {
        item.minZoom = 7
      }
    }
    viewMarker.push(item)
  }
  return viewMarker
}

/**
 * 坐标转换，国标经纬度转墨卡托
 * @param {Object} map - 地图对象
 * @param {Array} coordinates - 坐标数组 (longitude, latitude)
 * @returns {Array} - 转换后的坐标数组
 */
export function transformCoordinates(map, coordinates) {
  // 假设传入的是国标经纬度 (longitude, latitude)
  const mapProjection = map.getView().getProjection().getCode() // 获取地图的投影
  if (mapProjection == 'EPSG:4326') {
    return coordinates
  } else {
    return fromLonLat(coordinates) // 转换为墨卡托投影
  }
}

/**
 * 将点数组转换为 GeoJSON 格式
 * @param {Array<Object>} points - 点数组，每个点对象包含经度和纬度属性，例如 { lon: 123.456, lat: 78.901 }
 * @returns {Object} GeoJSON 格式的对象
 */
export function points2Geojson(data, featureType = 'label') {
  return {
    type: 'FeatureCollection',
    features: data.map((item) => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [item.lon, item.lat]
      },
      properties: {
        featureType,
        ...item
      }
    }))
  }
}
