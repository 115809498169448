<!--
 * @Name: 组件名
 * @Author: koyoyoo
 * @Date: 2025-01-16 13:50:45
-->
<template>
  <div ref="RMarkerHoverInteractionOverlay" class="cn-marker-hover-interaction">
    <slot :markerData="markerData"></slot>
  </div>
</template>
<script>
import { Select } from 'ol/interaction'
import { pointerMove } from 'ol/events/condition'
import VectorImageLayer from 'ol/layer/VectorImage'
import { transform } from 'ol/proj'
import Overlay from 'ol/Overlay'
export default {
  components: {},
  props: {
    map: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      mapPopOverlay: null,
      lastFeature: null,
      markerData: null
    }
  },
  watch: {},
  computed: {},
  mounted() {
    this.init()
    this.initHoverInteraction()
  },
  methods: {
    init() {
      this.mapPopOverlay = new Overlay({
        element: this.$refs.RMarkerHoverInteractionOverlay,
        positioning: 'bottom-center'
      })
      this.map.addOverlay(this.mapPopOverlay)
    },
    initHoverInteraction() {
      const hoverInteraction = new Select({
        condition: pointerMove,
        layers: (layer) => {
          return layer instanceof VectorImageLayer
        },
        filter: (feature) => {
          const data = feature.getProperties()
          return data?.markerType && data.interaction
        },
        style: null // 设置为null以保持原有样式
      })

      this.map.addInteraction(hoverInteraction)
      hoverInteraction.on('select', (e) => {
        const hoverFeature = e.selected[0]
        if (hoverFeature) {
          const data = hoverFeature.getProperties()
          if (data.interaction) {
            if (hoverFeature !== this.lastFeature) {
              if (data.interaction === 'popup-overlay') {
                // 将经纬度坐标转换为像素坐标
                let projectionCode = this.map
                  .getView()
                  .getProjection()
                  .getCode()
                const coordinates = transform(
                  [data.lon, data.lat],
                  'EPSG:4326',
                  projectionCode
                )
                this.mapPopOverlay.setPosition(coordinates)
                this.markerData = data
              }
            }
          } else {
            this.clearOverlay()
          }
          this.lastFeature = hoverFeature
        } else {
          this.lastFeature = null
          this.clearOverlay()
        }
      })
    },
    clearOverlay() {
      this.mapPopOverlay.setPosition(undefined)
    }
  }
}
</script>
<style lang="scss" scoped>
.cn-marker-hover-interaction {
  position: absolute;
  bottom: 0;
  left: 0;
}
</style>
