import { LineString } from 'ol/geom'
import { getLength } from 'ol/sphere'
import Api from '@/api/Api.js'
import { mergeMultiLineStrings } from './lineUtils.js'
import { points2Geojson } from '@/plug/map/openLayer/utils/utilsFn.js'
// 定义显示阈值常量，用于确定显示的最小距离要求（以米为单位）
const DISPLAY_THRESHOLD_METERS = 5

// 定义地理间隔常量，用于设定地图上点位之间的间隔（以像素为单位）
const GEO_INTERVAL_PIXELS = 500

/**
 * 根据给定的FeatureCollection和地图对象，生成在LineString上标注level的文字Feature数组。
 * @param {FeatureCollection} featureCollection - 包含LineString或MultiLineString Feature的GeoJSON FeatureCollection。
 * @param {Map} map - OpenLayers地图对象。
 * @param {String} key - 用于显示等值线值的字段。
 * @returns {Feature[]} levelTextFeatures - 存储带有level标签的Point Feature数组。
 *
 * 注意：
 * - DISPLAY_THRESHOLD 控制文字标签显示的阈值。
 * - 每个LineString会根据其长度和像素间隔计算出相应数量的标签点。
 * - 文字标签的样式包括黑色填充、白色描边，字体大小为14px Calibri或sans-serif。
 */
export function getFeaturesLineStringsLabels({
  map,
  featureCollection,
  key = 'level'
}) {
  const lineLabels = []
  let geojson = mergeMultiLineStrings(featureCollection)
  geojson.features.forEach((feature) => {
    if (
      feature.geometry.type === 'LineString' ||
      feature.geometry.type === 'MultiLineString'
    ) {
      // 如果是MultiLineString，迭代每个LineString
      const lineStrings =
        feature.geometry.type === 'MultiLineString'
          ? feature.geometry.coordinates
          : [feature.geometry.coordinates]
      const labelValue = Api.keep2(feature.properties[key])
      const resolution = map.getView().getResolution()
      const geoInterval = calculateGeoInterval(resolution)

      lineStrings.forEach((lineCoords) => {
        const lineString = new LineString(lineCoords)
        const lineLength = getLength(lineString)
        const displayLineLength = lineLength / resolution
        if (displayLineLength >= DISPLAY_THRESHOLD_METERS) {
          const numPoints = calculateNumPoints(lineLength, geoInterval)
          for (let i = 1; i <= numPoints; i++) {
            const fraction = calculateFraction(
              i,
              numPoints,
              lineLength,
              geoInterval
            )
            const pointCoords = lineString.getCoordinateAt(fraction)
            lineLabels.push({
              lon: pointCoords[0],
              lat: pointCoords[1],
              ...feature.properties,
              label: labelValue
            })
          }
        }
      })
    }
  })
  return points2Geojson(lineLabels)
}

/**
 * 根据分辨率计算地理间隔（单位像素）
 * @param {number} resolution - 地图的分辨率
 * @returns {number} - 计算出的地理间隔像素值
 */
export function calculateGeoInterval(resolution) {
  return GEO_INTERVAL_PIXELS * resolution
}

/**
 * 根据线段长度和地理间隔计算需要展示多少个文字标签
 * @param {number} lineLength - 线段的总长度
 * @param {number} geoInterval - 地理间隔
 * @returns {number} - 需要的点的数量，至少为1
 */
export function calculateNumPoints(lineLength, geoInterval) {
  return Math.max(1, Math.floor(lineLength / geoInterval))
}

/**
 * 计算线段上第i个点相对于线首的分数位置
 * @param {number} i - 点的索引，从0开始
 * @param {number} numPoints - 线段上的总点数
 * @param {number} lineLength - 线段的总长度
 * @param {number} geoInterval - 地理间隔
 * @returns {number} - 分数位置，范围在0到1之间
 */
export function calculateFraction(i, numPoints, lineLength, geoInterval) {
  return numPoints === 1 ? 0.5 : (geoInterval * i) / lineLength
}
