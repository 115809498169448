import { createRouter, createWebHistory } from 'vue-router'
import { setHeaderMeta } from '@/api/func/DataPro.js'
import Home from '../views/pages/VHome.vue'
const title = '中国气象局气候生态品牌创建示范平台'
const keyword =
  '气候生态品牌,中国天然氧吧,中国气候宜居城市(县),避暑旅游目的地,创建,示范,工作流程,实施细则,标准,效益评估,品牌,通知'
const description =
  '中国气象局气候生态品牌创建示范活动是指经过国务院批准，围绕气候资源的开发、利用和保护，采取必要的推动措施，动员组织相关地区或者单位开展品牌创建，通过评估、验收等方式，对符合标准的对象以通报、命名、授牌等形式予以认定，总结推广经验做法，发挥引领示范作用的活动。'
const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      animate: false,
      title,
      keyword,
      description,
      element: 'prec',
      footerIndex: 0,
      keepAlive: true,
      routerIndex: 0
    },
    component: Home
  },
  {
    path: '/newsDetails',
    name: 'newsDetails',
    meta: {
      animate: false,
      footerIndex: 2,
      keepAlive: true,
      routerIndex: 0,
      title,
      keyword,
      description
    },
    component: () => import('../views/pages/VNewsDetails.vue')
  },
  {
    path: '/brandDetail',
    name: 'brandDetail',
    meta: {
      animate: false,
      footerIndex: 2,
      keepAlive: true,
      routerIndex: 0,
      title,
      keyword,
      description
    },
    component: () => import('../views/pages/VBrandDetails.vue')
  },
  {
    path: '/cnoz',
    name: 'cnoz',
    meta: {
      animate: false,
      title: '中国天然氧吧-中国气象局气候生态品牌创建示范平台',
      keyword:
        '天然氧吧,氧吧,中国天然氧吧,组织,分工,通知,监督,管理,条件,申报,受理,资格审查,推动创建,评审,公示,认定公布,复查',
      description:
        '中国天然氧吧创建示范活动旨在践行“绿水青山就是金山银山”的生态发展理念和国家生态文明发展战略,通过保护和利用高质量的生态旅游气候资源，倡导绿色、生态的生活理念，发展生态旅游、健康旅游，推动生态气候资源优势转化为产业优势。中国天然氧吧创建示范工作坚持科学性、真实性、公益性和公开、公正、公平的原则，接受中国气象局的监督管理。',
      footerIndex: 3,
      keepAlive: true,
      routerIndex: 0
    },
    component: () => import('../views/pages/VPageOxy.vue')
  },
  {
    path: '/cclc',
    name: 'cclc',
    meta: {
      animate: false,
      title: '中国气候宜居城市(县)-中国气象局气候生态品牌创建示范平台(县)页面',
      keyword: '中国气候宜居城市,宜居,气候宜居',
      description:
        '中国气候宜居城市（县）创建示范活动是对区域气候资源及与之相关联的宜居环境等开展的科学评估，旨在充分挖掘并发挥地方气候资源优势，打造国家级气候宜居品牌，助推美丽中国建设和地方经济社会发展，是气象部门服务民生、推进生态文明建设的重要举措，是全国评比达标表彰工作协调小组批准设立的创建示范项目。',
      footerIndex: 4,
      keepAlive: true,
      routerIndex: 0
    },
    component: () => import('../views/pages/VPageCity.vue')
  },
  {
    path: '/ccsd',
    name: 'ccsd',
    meta: {
      animate: false,
      title: '避暑旅游目的地-中国气象局气候生态品牌创建示范平台',
      keyword: '避暑旅游目的地,避暑旅游,旅游目的地',
      description:
        '避暑旅游目的地创建示范活动是指对区域避暑旅游气候资源及与之相关联的生态环境、气候景观、气候风险等进行科学权威评估的气象服务工作。旨在充分挖掘并发挥地方避暑气候资源优势，助推美丽中国建设和地方经济社会发展，是气象部门服务民生、推进生态文明建设的重要举措，是全国评比达标表彰工作协调小组批准设立的创建示范项目。',
      footerIndex: 5,
      keepAlive: true,
      routerIndex: 0
    },
    component: () => import('../views/pages/VPageAvoidheat.vue')
  },
  {
    path: '/tsncp',
    name: 'tsncp',
    meta: {
      animate: false,
      title: '气候特色农产品-中国气象局气候生态品牌创建示范平台',
      keyword: '气候特色农产品,农产品,气候',
      description: description,
      footerIndex: 6,
      keepAlive: true,
      routerIndex: 0
    },
    component: () =>
      import('../views/pages/pageAgriProduct/VPageAgriProduct.vue')
  },
  {
    path: '/newsList',
    name: 'newsList',
    meta: {
      animate: false,
      title,
      keyword,
      description,
      footerIndex: 60,
      keepAlive: true,
      routerIndex: 0
    },
    component: () => import('../views/pages/VNewsList.vue')
  },
  {
    path: '/newsXYPGList',
    name: 'newsXYPGList',
    meta: {
      animate: false,
      title: '气候生态品牌效益评估-中国气象局气候生态品牌创建示范平台',
      keyword:
        '品牌效益,生态效益,经济效益,社会效益,效益评估,标准,指标,氧吧效益,天然氧吧效益',
      description:
        '中国气象局气候生态品牌创建示范活动得到了各地政府的积极响应、各级气象部门及相关行业部门的热情参与、社会媒体的大力助推，品牌效益显著提升，有力促进了各地经济社会的发展。',
      footerIndex: 70,
      keepAlive: true,
      routerIndex: 0
    },
    component: () => import('../views/pages/VNewsXYPGList.vue')
  },
  {
    path: '/cnozDetail',
    name: 'cnozDetail',
    meta: {
      animate: false,
      title,
      keyword,
      description,
      footerIndex: 80,
      keepAlive: true,
      routerIndex: 0
    },
    component: () => import('../views/pages/VBrandOxyDetails.vue')
  },
  {
    path: '/brandPicList',
    name: 'brandPicList',
    meta: {
      animate: false,
      title,
      keyword,
      description,
      footerIndex: 90,
      keepAlive: true,
      routerIndex: 0
    },
    component: () => import('../views/pages/VBrandPicList.vue')
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    meta: {
      animate: false,
      title: '关于我们-中国气象局气候生态品牌创建示范平台',
      keyword: '气候生态品牌,中国气象局,气象局',
      description:
        '中国气象局气候生态品牌创建示范活动是指经过国务院批准，围绕气候资源的开发、利用和保护，采取必要的推动措施，动员组织相关地区或者单位开展品牌创建，通过评估、验收等方式，对符合标准的对象以通报、命名、授牌等形式予以认定，总结推广经验做法，发挥引领示范作用的活动。',
      footerIndex: 100,
      keepAlive: true,
      routerIndex: 0
    },
    component: () => import('../views/pages/VPageAboutUs.vue')
  },
  {
    path: '/pdfList',
    name: 'pdfList',
    meta: {
      animate: false,
      title,
      keyword,
      description,
      footerIndex: 110,
      keepAlive: true,
      routerIndex: 0
    },
    component: () => import('../views/pages/VPdfList.vue')
  },
  {
    path: '/build',
    name: 'build',
    meta: {
      animate: false,
      title,
      keyword,
      description,
      footerIndex: 120,
      keepAlive: true,
      routerIndex: 0
    },
    component: () => import('../views/pages/VPageBuild.vue')
  },
  {
    path: '/book',
    name: 'book',
    meta: {
      animate: false,
      title,
      keyword,
      description,
      footerIndex: 130,
      keepAlive: true,
      routerIndex: 0
    },
    component: () => import('../views/pages/VPageBook.vue')
  },
  {
    path: '/cnoz/oxyType',
    name: '/cnoz/oxyType',
    meta: {
      animate: false,
      title,
      keyword,
      description,
      footerIndex: 9,
      keepAlive: true,
      routerIndex: 0
    },
    component: () => import('../views/pages/VPageOxyTypeView.vue')
  },
  {
    path: '/suyuan/list',
    name: '/suyuan/list',
    meta: {
      animate: false,
      title,
      keyword,
      description,
      footerIndex: 10,
      keepAlive: true,
      routerIndex: 0
    },
    component: () => import('../views/pages/pageSuyuan/VPageSuyuanList.vue')
  },
  {
    path: '/suyuan/detail',
    name: '/suyuan/detail',
    meta: {
      animate: false,
      title,
      keyword,
      description,
      footerIndex: 11,
      keepAlive: true,
      routerIndex: 0
    },
    component: () =>
      import('../views/pages/pageSuyuan/detail/VPageSuyuanDetail.vue')
  },
  {
    path: '/test',
    name: 'test',
    meta: {
      animate: false,
      title,
      keyword,
      description,
      footerIndex: 9,
      keepAlive: true,
      routerIndex: 0
    },
    component: () => import('../views/pages/pageTest/VPageTest.vue')
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    meta: {
      animate: false,
      title,
      keyword,
      description,
      element: 'prec',
      footerIndex: 0,
      keepAlive: true,
      routerIndex: 0
    },
    component: () => import('../views/pages/VPage404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  //history: createWebHashHistory(process.env.BASE_URL), //采用hash路由模式
  routes
})
router.beforeEach((to, from, next) => {
  //beforeEach是router的钩子函数，在进入路由前执行
  //判断是否有标题
  setHeaderMeta(to)
  if (window._hmt) {
    if (to.path) {
      window._hmt.push(['_trackPageview', '/#' + to.fullPath])
    }
  }
  next() //执行进入路由，如果不写就不会进入目标页
})

export default router
