<template>
  <ChartCore ref="RChartView"></ChartCore>
</template>
<script>
import { getLinearColor } from '@/plug/chart/chartFn.js'
import ChartCore from '@/components/content/chart/ChartCore.vue'
//import { nowSize } from '@/plug/chart/chartFn.js'
export default {
  log: '',
  components: { ChartCore },
  props: {
    fmt: {
      type: String,
      default: 'YYYY'
    }
  },
  data() {
    return {
      listData: [],
      xAxisData: [],
      title: '',
      option: {}
    }
  },
  watch: {},
  computed: {},
  updated() {},
  mounted() {
    this.initData()
    this.initEvent()
  },
  methods: {
    initEvent() {
      window.addEventListener('resize', () => {
        this.initData()
      })
    },
    initData(list) {
      if (list) {
        this.listData = list ?? []
        this.xAxisData = list.map((e) => {
          return e.name
        })
      }
      let option = this.getData()
      if (this.$refs.RChartView) {
        if (option) {
          this.$refs.RChartView.initData(option)
        } else {
          this.$refs.RChartView.initData(option)
        }
      }
    },
    getData() {
      let opt = {
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          //调整图表上下左右位置
          top: 30,
          left: 10,
          right: 20,
          bottom: 0,
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            axisTick: {
              alignWithLabel: true
            },
            data: this.xAxisData,
            axisLabel: {
              //fontSize: nowSize(16)
              /*formatter: (e) => {
                return e.split('').join('\n')
              }*/
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '个',
            position: 'left',
            alignTicks: true,
            minInterval: 1,
            axisLine: {
              show: true
            },
            splitLine: {},
            axisLabel: {
              formatter: '{value}'
              //fontSize: nowSize(16)
            }
          }
        ],
        series: [
          {
            type: 'line',
            data: this.listData,
            smooth: true,
            label: {
              color: '#14b8d4'
            },
            lineStyle: {
              color: '#14b8d4'
            },
            itemStyle: {
              color: '#14b8d4'
            },
            areaStyle: {
              color: getLinearColor(['rgba(0,184,90,0.5)', 'rgba(0,184,90,0)'])
            }
          }
        ]
      }
      return opt
    }
  }
}
</script>
<style lang="scss" scoped></style>
