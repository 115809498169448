<template>
  <div class="oxy-right-data-view" :class="layerState">
    <div class="top-handler icon-cb fenbu1" @click="EventChange_layer"></div>
    <div class="inner-view">
      <div class="hidden-box">
        <div class="title">
          <div class="name">{{ PageRoot.brandName }}</div>
        </div>
        <CpnProductState
          class="mgb-40"
          stateType="default"
          :baseCount="totalCount"
          :productCount="applyCount"
          areaName="全国"
        ></CpnProductState>
        <CpnBuild></CpnBuild>
      </div>
    </div>
  </div>
</template>
<script>
import CpnBuild from '@/components/content/none/CpnBuild.vue'
import { MapData } from '../js/MapPlugAdminPolygon.js'
import CpnProductState from './otherDataView/CpnProductState'
export default {
  log: '',
  inject: ['PageRoot'],
  components: {
    CpnBuild,
    CpnProductState
  },
  props: {},
  data() {
    return {
      layerState: 'close',
      totalCount: 19,
      applyCount: 0,
      MapData
    }
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    EventChange_layer() {
      if (this.layerState == 'close') {
        this.layerState = 'open'
      } else {
        this.layerState = 'close'
      }
      this.$emit('handlerChange', this.layerState)
    }
  }
}
</script>
<style lang="scss" scoped>
.oxy-right-data-view {
  position: relative;
  margin-top: 20px;
  padding: 5px;
  .inner-view {
    position: absolute;
    width: 365px;
    top: 50px;
    right: 0px;
    background: #fff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    transition: 0.1s ease-out;
    overflow: hidden;
  }
  .hidden-box {
    width: 345px;
    padding: 10px 10px 20px 10px;
  }
  &.close {
    .inner-view {
      width: 0;
    }
  }
  .title {
    padding: 10px 0;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    .name {
      padding: 8px 15px;
      font-size: 18px;
      border-radius: 20px;
      color: #fff;
      background: #23c4e0;
      font-weight: bold;
    }
  }
  .line {
    margin: 20px 10px;
    border-bottom: 1px solid #eee;
  }
  .m-chart-pie-state {
    height: 240px;
  }
  .m-chart-line-state {
    height: 160px;
  }
  .win-handler {
    position: absolute;
    top: 50%;
    left: -24px;
    transform: translateY(-50%);
    background: #fff;
    width: 24px;
    text-align: center;
    padding: 10px 0;
    box-shadow: -2px 0 4px rgba(0, 0, 0, 0.2);
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    &.close::after {
      content: '展开';
    }
    &:hover::after {
      color: #23c4e0;
    }
    &::after {
      content: '收起';
      font-size: 14px;
      color: #666;
      cursor: pointer;
    }
  }
  .top-handler {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #89b4b6;
    background: #d3eff2;
    color: #7ba6a8;
    border-radius: 3px;
    cursor: pointer;
    &:hover {
      border: 1px solid #23c4e0;
      background: #23c4e0;
      color: #fff;
    }
  }
}
</style>
