import ModuleDrawLineLabel from '@/plug/map/openLayer/module/ModuleDrawLineLabel.js'
import { points2Geojson } from '@/plug/map/openLayer/utils/utilsFn.js'
import { KOMap } from './initMap.js'

export function drawMarkers(markerList) {
  let map = KOMap.value
  let geojsonPoint = points2Geojson(markerList)
  if (!map.PlugDrawIconPoint) {
    map.PlugDrawIconPoint = new ModuleDrawLineLabel({
      map: map,
      moveEvent: false
    })
  }
  map.PlugDrawIconPoint.update(geojsonPoint)
}
