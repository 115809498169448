import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector.js'
import Select from 'ol/interaction/Select.js'
import { pointerMove, click } from 'ol/events/condition.js'
import { Style, Fill, Stroke } from 'ol/style.js'

class FeatureHoverInteraction {
  zIndex = 40
  hoverFeatureTypes = ['polygon', 'admin-polygon']
  clickFeatureTypes = ['polygon', 'admin-polygon']
  constructor(ret) {
    Object.assign(this, ret)
    this.createLayer()
    this.initInteractionHover()
    this.initInteractionClick()
  }
  createLayer() {
    this.vectorLayer = new VectorLayer({
      source: new VectorSource(),
      properties: { type: 'polygonHoverLayer' },
      zIndex: this.zIndex
    })
  }
  initInteractionClick() {
    this.click = new Select({
      condition: click,
      // 过滤特征，仅选择多边形特征
      filter: (feature) => {
        return this.clickFeatureTypes.includes(feature.get('featureType'))
      },
      style: false
    })
    this.click.on('select', (event) => {
      const selectedFeatures = event.selected
      if (typeof this.EventClick == 'function') {
        this.EventClick(selectedFeatures)
      }
    })
    this.map.addInteraction(this.click)
  }
  // 初始化交互
  initInteractionHover() {
    this.select = new Select({
      condition: pointerMove,
      // 过滤特征，仅选择多边形特征
      filter: (feature) => {
        return this.hoverFeatureTypes.includes(feature.get('featureType'))
      },
      style: (feature) => this.createHoverStyle(feature)
    })

    this.select.on('select', (event) => {
      const selectedFeatures = event.selected
      const deselectedFeatures = event.deselected
      // 对于选中的feature，添加临时feature并应用渐变效果
      selectedFeatures.forEach((feature) => {
        this.createTemporaryFeature(feature)
      })

      // 对于取消选中的feature，移除临时feature
      deselectedFeatures.forEach((feature) => {
        this.removeTemporaryFeature(feature)
      })
      if (typeof this.EventSelect == 'function') {
        this.EventSelect(selectedFeatures)
      }
    })

    this.map.addInteraction(this.select)
  }

  // 创建临时feature并应用渐变效果
  createTemporaryFeature(feature) {
    const source = this.vectorLayer.getSource()
    const tempFeatureId = `temp-${feature.getId()}`
    let tempFeature = source.getFeatureById(tempFeatureId)

    if (!tempFeature) {
      tempFeature = feature.clone()
      tempFeature.setId(tempFeatureId)
      tempFeature.set('isTemporary', true)
      tempFeature.setStyle(this.createTemporaryStyle(feature))
      source.addFeature(tempFeature)
    }
  }

  // 移除临时feature
  removeTemporaryFeature(feature) {
    const source = this.vectorLayer.getSource()
    const tempFeatureId = `temp-${feature.getId()}`
    const tempFeature = source.getFeatureById(tempFeatureId)
    if (tempFeature) {
      source.removeFeature(tempFeature)
    }
  }

  // 创建临时feature的样式
  createTemporaryStyle(feature) {
    return this.createStyle(feature)
  }

  // 创建鼠标悬停时的样式
  createHoverStyle(feature) {
    return this.createStyle(feature)
  }

  // 创建样式的通用方法
  createStyle(feature) {
    const fillColor = feature.get('fillHoverColor') || 'rgba(0,0,0,0.1)'
    const strokeColor = feature.get('strokeHoverColor') || 'rgba(0,0,0,1)'
    const strokeWidth = (feature.get('strokeWidth') || 1) ?? 1
    return new Style({
      fill: new Fill({
        color: fillColor
      }),
      stroke: new Stroke({
        color: strokeColor,
        width: strokeWidth
      })
    })
  }

  // 销毁方法
  destroy() {
    this.map.removeInteraction(this.select)
    this.map.removeInteraction(this.click)
    this.vectorLayer.getSource().clear()
    this.vectorLayer = null
    this.select = null
    this.click = null
  }
}

export default FeatureHoverInteraction
