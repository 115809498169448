import GeoJSON from 'ol/format/GeoJSON'
import VectorSource from 'ol/source/Vector'
import VectorImageLayer from 'ol/layer/VectorImage'
import { Style, Stroke, Fill, Text, Icon, Circle } from 'ol/style'
import { getFeaturesLineStringsLabels } from '@/plug/map/openLayer/line/mapFeaturesLineString.js'
import { Debouncer } from '@/api/utilsFn.js'

/**
 * 用于绘制等压线和点标签的类，包括线特征和名称标签
 * geojson数据的feature属性说明：
 * ** 图标
 * properties.styleType=='icon' //则会渲染图标类型的点
 * properties.icon //图标的url
 * properties.scale //图标的缩放
 * properties.rotation //图标的旋转-度
 * ** 线
 * properties.lineColor //用于设置线颜色
 * properties.lineWidth //用于设置线宽度
 * ** 文本
 * properties.styleType=='text' //则会渲染文本类型的点
 * properties.label //用于设置标签显示的文本
 * properties.fillColor //用于设置文本颜色
 * properties.strokeColor //用于设置文本边框颜色
 * properties.textColor //用于设置文本颜色
 * ** 图标+文本
 * properties.styleType=='icon-text' //则会渲染图标+文本类型的点
 * properties.icon //图标的url
 * properties.scale //图标的缩放
 * properties.rotation //图标的旋转-度
 */
class ModuleDrawLineLabel {
  map // 地图对象
  lineLabelKey = 'level' // 把线的数据用作标签显示到线上的键值，默认为'ZValue'
  layers = {} // 存储图层的对象
  moveEvent = true // 是否开启moveend事件

  /**
   * 构造函数，初始化类的属性和事件监听
   * @param {Object} props - 初始化参数
   * @param {Map} props.map - 地图对象
   * @param {string} [props.key="ZValue"] - 数据键值
   */
  constructor(props) {
    Object.assign(this, props)
    this.projectionCode = this.map.getView().getProjection().getCode()
    if (this.projectionCode === 'EPSG:4326') {
      this.geojsonFormat = new GeoJSON() // GeoJSON格式转换器
    } else {
      this.geojsonFormat = new GeoJSON({
        dataProjection: 'EPSG:4326',
        featureProjection: this.projectionCode
      }) // GeoJSON格式转换器
    }
    this.init()
  }

  /**
   * 初始化类，创建图层并设置事件监听
   */
  init() {
    this.isVisible = {}
    this.createLayers()
    if (this.moveEvent) {
      this.debouncedFunction = new Debouncer(() => {
        this.drawLineString(this.geojsonData) // 绘制等压线-线特征
        this.drawLineLabel(this.geojsonData) // 绘制等压线-名称标签
        this.drawPointLabels(this.geojsonData) // 绘制点标签
      })
      this.deFn = () => {
        this.debouncedFunction.call()
      }
      this.map.on('moveend', this.deFn)
    }
  }

  /**
   * 创建所有图层
   */
  createLayers() {
    const layerConfig = {
      //线
      lineString: {
        type: 'lineLayer',
        zIndex: 60,
        style: (feature) => {
          let prop = feature.getProperties()
          return this.getLineStyle(prop)
        }
      },
      //线上的label
      lineLabel: {
        type: 'lineLabelLayer',
        zIndex: 75,
        style: (feature) => {
          let prop = feature.getProperties()
          return this.getPointLabelStyle(prop)
        }
      },
      //点label
      pointLabel: {
        type: 'pointLabelLayer',
        zIndex: 80,
        style: (feature) => {
          let prop = feature.getProperties()
          return this.getPointLabelStyle(prop)
        }
      }
    }

    for (const i in layerConfig) {
      const config = layerConfig[i]
      const source = new VectorSource()
      const layer = new VectorImageLayer({
        properties: { type: config.type },
        source: source,
        zIndex: config.zIndex,
        style: config.style
      })
      this.layers[i] = layer
      this.map.addLayer(layer)
    }
  }

  refresh() {
    this.drawLineString(this.geojsonData) // 绘制等压线-线特征
    this.drawLineLabel(this.geojsonData) // 绘制等压线-名称标签
    this.drawPointLabels(this.geojsonData) // 绘制点标签
  }

  /**
   * 更新等压线数据，并包含点标签的更新
   * @param {Object} geojsonData - 新的等压线数据集合
   */
  update(geojsonData) {
    if (
      JSON.stringify(geojsonData?.features) ==
      JSON.stringify(this.geojsonData?.features)
    ) {
      return
    }
    this.geojsonData = geojsonData
    this.refresh()
  }

  setVisible(key, value, bool) {
    if (typeof value == 'undefined') {
      console.error('value未定义：' + value)
    }
    if (!this.geojsonData?.features?.length) return
    // 遍历所有features,找到匹配的feature并设置其visible属性
    this.geojsonData.features.forEach((feature) => {
      if (feature.properties[key] === value) {
        feature.properties.visible = bool
      }
    })
    this.drawLineString(this.geojsonData) // 绘制等压线-线特征
    this.drawLineLabel(this.geojsonData) // 绘制等压线-名称标签
    this.drawPointLabels(this.geojsonData) // 绘制点标签
  }

  getVisibleGeojson(geojsonData) {
    if (!geojsonData?.features?.length) return
    const features = geojsonData.features?.filter(
      (feature) => feature.properties.visible !== false
    )
    return {
      type: 'FeatureCollection',
      features: features
    }
  }

  clear() {
    // 清除所有图层的数据源
    for (const layerKey in this.layers) {
      const source = this.layers[layerKey]?.getSource()
      source?.clear()
    }
    // 清除geojsonData
    this.geojsonData = null
  }

  /**
   * 绘制等压线的线特征
   */
  drawLineString(geojsonData) {
    const source = this.layers.lineString?.getSource()
    source?.clear()
    const visibleGeojson = this.getVisibleGeojson(geojsonData)
    if (visibleGeojson?.features?.length) {
      const isobaricFeatures = this.geojsonFormat.readFeatures(visibleGeojson)
      source.addFeatures(isobaricFeatures)
    }
  }

  /**
   * 绘制等压线的名称标签
   */
  drawLineLabel(geojsonData) {
    const source = this.layers.lineLabel?.getSource()
    source?.clear()
    const visibleGeojson = this.getVisibleGeojson(geojsonData)
    if (visibleGeojson?.features?.length) {
      const labelGeojsonData = getFeaturesLineStringsLabels({
        map: this.map,
        featureCollection: visibleGeojson,
        key: this.lineLabelKey
      })
      labelGeojsonData.features.forEach((feature) => {
        const pointFeature = this.geojsonFormat.readFeature(feature)
        source.addFeature(pointFeature)
      })
    }
  }

  /**
   * 绘制FeatureCollection中type="Point"的Feature的标签
   */
  drawPointLabels(geojsonData) {
    const source = this.layers.pointLabel?.getSource()
    source?.clear()
    const visibleGeojson = this.getVisibleGeojson(geojsonData)
    if (visibleGeojson?.features?.length) {
      const pointFeatures = visibleGeojson.features.filter(
        (feature) =>
          feature.type === 'Feature' && feature.geometry.type === 'Point'
      )
      pointFeatures.forEach((feature) => {
        if (feature.properties) {
          const pointFeature = this.geojsonFormat.readFeature(feature)
          source.addFeature(pointFeature)
        }
      })
    }
  }
  getLineStyle(prop) {
    return new Style({
      stroke: new Stroke({
        color: prop.lineColor ?? 'black', // 使用lineColor变量
        width: prop.lineWidth ?? 1
      })
    })
  }

  getPointLabelStyle(prop) {
    //图标类型的点
    if (prop.styleType == 'point') {
      return new Style({
        image: new Circle({
          radius: prop.pointRadius ?? 5,
          fill: new Fill({
            color: prop.pointColor ?? 'black'
          }),
          stroke: new Stroke({ color: prop.strokeColor ?? 'white', width: 1 })
        })
      })
      //文本类型的点
    } else if (prop.styleType == 'icon') {
      return new Style({
        image: new Icon({
          src: prop.icon,
          scale: prop.scale ?? 0.07, //图标缩放
          rotation: prop.rotation, //图标旋转-度
          anchorOrigin: prop.anchorOrigin ?? [0, 0],
          anchor: prop.anchor ?? [0.5, 0.5] // 设置锚点为底部中心
        })
      })
      //文本类型的点
    } else if (prop.styleType == 'text') {
      return new Style({
        text: new Text({
          text: prop.label?.toString(),
          font: `${prop.bold ? 'bold' : ''} ${
            prop.fontSize ?? '14px'
          } sans-serif`,
          fill: new Fill({ color: prop.fillColor ?? 'black' }),
          stroke: new Stroke({ color: prop.strokeColor ?? 'white', width: 2 }),
          offsetX: prop.offsetX ?? 0,
          offsetY: prop.offsetY ?? 0
        })
      })
    } else if (prop.styleType == 'icon-text') {
      return new Style({
        image: new Icon({
          src: prop.icon,
          scale: prop.scale ?? 0.07, //图标缩放
          rotation: prop.rotation ? this.degreesToRadians(prop.rotation) : 0, //图标旋转-度
          anchorOrigin: prop.anchorOrigin ?? [0, 0],
          anchor: prop.anchor ?? [0.5, 0.5] // 设置锚点为底部中心
        }),
        text: new Text({
          text: prop.label?.toString(),
          font: `${prop.bold ? 'bold' : ''} ${
            prop.fontSize ?? '14px'
          } sans-serif`,
          fill: new Fill({ color: prop.fillColor ?? 'black' }),
          stroke: new Stroke({ color: prop.strokeColor ?? 'white', width: 2 }),
          offsetX: prop.offsetX ?? 0,
          offsetY: prop.offsetY ?? 0,
          textAlign: prop.textAlign ?? 'center'
        })
      })
    } else if (prop.styleType == 'point-text') {
      return new Style({
        image: new Circle({
          radius: 4,
          fill: new Fill({
            color: prop.pointColor ?? 'black'
          }),
          stroke: new Stroke({ color: prop.strokeColor ?? 'white', width: 1 })
        }),
        text: new Text({
          text: prop.label?.toString(),
          font: `${prop.bold ? 'bold' : ''} ${
            prop.fontSize ?? '14px'
          } sans-serif`,
          fill: new Fill({ color: prop.fillColor ?? 'black' }),
          stroke: new Stroke({ color: prop.strokeColor ?? 'white', width: 2 }),
          offsetX: prop.offsetX ?? 0,
          offsetY: prop.offsetY ?? 0,
          textAlign: prop.textAlign ?? 'center'
        })
      })
    } else {
      console.error(
        prop,
        'styleType不存在,请为此点设置styleType:icon,text,icon-text'
      )
    }
  }
  /**
   * 将度数转换为弧度
   * @param {number} degrees - 度数
   * @returns {number} 弧度
   */
  degreesToRadians(degrees) {
    return degrees * (Math.PI / 180)
  }

  /**
   * 设置图层的可见性
   * @param {string} key - 要设置的标记的key
   * @param {boolean} isVisible - 如果为true则显示图层，false则隐藏图层
   */
  setKeyVisible(key, isVisible) {
    this.isVisible[key] = isVisible
    for (const layerKey in this.layers) {
      this.layers[layerKey].setVisible(
        Object.values(this.isVisible).every((value) => value === true)
      )
    }
  }

  /**
   * 销毁类实例，清理资源
   */
  destroy() {
    // 移除图层
    for (const i in this.layers) {
      this.map.removeLayer(this.layers[i])
    }

    // 取消事件监听
    this.map.un('moveend', this.deFn)

    // 清理 debouncer
    this.debouncedFunction?.cancel()

    // 清理引用
    this.layers = null
    this.map = null
    this.geojsonData = null
  }
}
export default ModuleDrawLineLabel
