import { InfoMap_pieProductList } from '@/info/infoOxyMapData.js'

const testData = {
  data: {
    provinceList: [
      {
        provinceName: '云南省',
        province: '530000',
        total: 0
      },
      {
        provinceName: '浙江省',
        province: '330000',
        total: 0
      },
      {
        provinceName: '陕西省',
        province: '610000',
        total: 0
      },
      {
        provinceName: '江西省',
        province: '360000',
        total: 1
      },
      {
        provinceName: '四川省',
        province: '510000',
        total: 3
      },
      {
        provinceName: '黑龙江省',
        province: '230000',
        total: 0
      },
      {
        provinceName: '湖北省',
        province: '420000',
        total: 0
      },
      {
        provinceName: '湖南省',
        province: '430000',
        total: 2
      },
      {
        provinceName: '河南省',
        province: '410000',
        total: 0
      },
      {
        provinceName: '安徽省',
        province: '340000',
        total: 0
      },
      {
        provinceName: '福建省',
        province: '350000',
        total: 0
      },
      {
        provinceName: '重庆市',
        province: '500000',
        total: 3
      },
      {
        provinceName: '山西省',
        province: '140000',
        total: 1
      },
      {
        provinceName: '广东省',
        province: '440000',
        total: 0
      },
      {
        provinceName: '广西壮族自治区',
        province: '450000',
        total: 0
      },
      {
        provinceName: '江苏省',
        province: '320000',
        total: 1
      },
      {
        provinceName: '内蒙古自治区',
        province: '150000',
        total: 1
      },
      {
        provinceName: '新疆维吾尔自治区',
        province: '650000',
        total: 1
      },
      {
        provinceName: '海南省',
        province: '460000',
        total: 0
      },
      {
        provinceName: '辽宁省',
        province: '210000',
        total: 0
      },
      {
        provinceName: '河北省',
        province: '130000',
        total: 1
      },
      {
        provinceName: '北京市',
        province: '110000',
        total: 0
      },
      {
        provinceName: '贵州省',
        province: '520000',
        total: 2
      },
      {
        provinceName: '甘肃省',
        province: '620000',
        total: 1
      },
      {
        provinceName: '山东省',
        province: '370000',
        total: 0
      },
      {
        provinceName: '西藏自治区',
        province: '540000',
        total: 2
      },
      {
        provinceName: '吉林省',
        province: '220000',
        total: 0
      },
      {
        provinceName: '宁夏回族自治区',
        province: '640000',
        total: 0
      },
      {
        provinceName: '天津市',
        province: '120000',
        total: 0
      },
      {
        provinceName: '上海市',
        province: '310000',
        total: 0
      }
    ]
  }
}

/**
 * @产品数量统计_饼图
 */
export function Inter_mapDistribution(param) {
  return new Promise((resolve) => {
    if (param.brandType == 1) {
      resolve(testData)
    } else {
      InfoMap_pieProductList(param).then((ret) => {
        resolve(ret)
      })
    }
  })
}
