import { ref } from 'vue'
import { Map, View } from 'ol'
import Api from '@/api/Api.js'

import { MapData, AdminPlug, destroyMapPlug } from './MapPlugAdminPolygon.js'
import { defaults as defaultInteractions } from 'ol/interaction'
import { viewBounds } from '@/plug/map/openLayer/utils/utilsFn.js'
import { initMapInteraction, destroyMapInteraction } from './MapInteraction'
import { toLonLat } from 'ol/proj.js'

const projection = 'EPSG:3857' //底图是EPSG:3857的标准///4326

export const KOMap = ref(null)

export function initMap(ret) {
  const def = {
    ...Api.getMapConfig(),
    center: [103.5, 34],
    zoom: 4.35
  }
  let opt = Object.assign({}, def, ret)
  let layers = []
  const map = new Map({
    target: opt.id,
    controls: [
      //new ScaleLine() // 添加比例尺控件
    ],
    layers: layers,
    view: new View({
      center: [115.33302969137772, 33.28759089271208],
      zoom: 7,
      projection: projection // 使用EPSG:4326坐标系
    }),
    interactions: defaultInteractions({
      mouseWheelZoom: false, // 禁用鼠标滚轮缩放
      doubleClickZoom: false // 禁用双击放大功能
    })
  })
  map?.on('click', (e) => {
    if (e.originalEvent.altKey) {
      console.log(toLonLat(e.coordinate))
    }
  })
  KOMap.value = map
  initMapInteraction()
  return map
}

export function zoomCtrl(ret) {
  //改变地图大小，放大缩小
  let view = KOMap.value.getView()
  let zoom = KOMap.value.getView().getZoom()
  if (ret === 'add') {
    view.animate({ zoom: zoom + 1, duration: 200 })
  } else if (ret === 'sub') {
    view.animate({ zoom: zoom - 1, duration: 200 })
  } else if (ret === 'resize') {
    resizeMap()
  }
}

export function destroyMap() {
  KOMap.value.setTarget(null) // This will effectively destroy the map instance
  KOMap.value = null
  //销毁地图行政区划多变形组件
  AdminPlug.value?.destroy()
  AdminPlug.value = null
  destroyMapPlug()
  destroyMapInteraction()
}

export function resizeMap() {
  KOMap.value?.updateSize()
  if (MapData.areaCode == 100000) {
    viewBounds(KOMap.value, [72, 17, 135, 54], [40, 50, 40, 40])
  } else {
    AdminPlug.value.viewport()
  }
}
